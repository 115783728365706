import './index.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
import router from '@/router/index.js'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import App from './App.vue'

const app = createApp(App)

Sentry.init({
  dsn: "https://4d251ce6b729c5baaead0e3111ab66e7@o4505891611279360.ingest.sentry.io/4505891614425088",
  // Filter out default `Vue` integration
  integrations: integrations => integrations.filter(integration => integration.name !== 'Vue'),
});

Sentry.addIntegration(Sentry.vueIntegration({ app }));

app.use(router)
app.use(ToastPlugin)
app.use(createPinia())
app.mount('#app')
