<template>
  <div
    v-if="isDialogOpen"
    class="relative z-10"
    aria-labelledby="slide-over-title"
    role="dialog"
    aria-modal="true"
    data-testid="todo-details-dialog"
  >
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0"></div>

    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div class="pointer-events-auto w-screen max-w-md">
            <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
              <div class="bg-blue-600 px-4 py-6 sm:px-6">
                <div class="flex items-center justify-between">
                  <h2
                    class="text-base font-semibold leading-6 text-white"
                    id="slide-over-title"
                    data-testid="title"
                  >
                    My Profile
                  </h2>
                  <div class="ml-3 flex h-7 items-center">
                    <button
                      @click="closeDialog"
                      type="button"
                      class="rounded-md bg-blue-600 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span class="sr-only">Close</span>
                      <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative flex-1 px-4 py-6 sm:px-6">
                <div class="flex items-start">
                  <div 
                    class="relative flex-shrink-0 cursor-pointer h-24 w-24 rounded-full overflow-hidden"
                    @click="uploadAvatar"
                  >
                    <img 
                      v-if="!hovered" 
                      class="h-full w-full object-cover transition ease-in-out duration-150"
                      :src="displayProfileImage" 
                      alt="avatar" 
                      @mouseover="hovered = true"
                    />
                    <div 
                      v-if="hovered" 
                      class="absolute inset-0 flex items-center justify-center bg-gray-600"
                      @mouseleave="hovered = false"
                    >
                      <PencilIcon class="h-8 w-8 text-white" />
                    </div>
                  </div>
                  <div v-if="isEdit" class="ml-4 flex-grow">
                    <form @submit.prevent="submitForm">
                      <div class="grid gap-4 mb-4 md:grid-cols-1">
                        <div
                          class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
                        >
                          <label for="name" class="block text-xs font-medium text-gray-600"
                            >First Name</label
                          >
                          <input
                            type="text"
                            name="firstName"
                            v-model="formData.firstName"
                            id="firstName"
                            class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="grid gap-4 mb-4 md:grid-cols-1">
                        <div
                          class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
                        >
                          <label for="name" class="block text-xs font-medium text-gray-600"
                            >Last Name</label
                          >
                          <input
                            type="text"
                            name="lastName"
                            v-model="formData.lastName"
                            id="lastName"
                            class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="grid gap-4 mb-4 md:grid-cols-1">
                        <div
                          class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
                        >
                          <label for="name" class="block text-xs font-medium text-gray-600"
                            >Email</label
                          >
                          <input
                            type="text"
                            name="email"
                            v-model="formData.email"
                            id="email"
                            class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="grid gap-4 mb-4 md:grid-cols-1">
                        <div
                          class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
                        >
                          <label for="name" class="block text-xs font-medium text-gray-600"
                            >Phone</label
                          >
                          <input
                            type="text"
                            name="phone"
                            v-model="formData.phone"
                            id="phone"
                            class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          type="submit"
                          class="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                  <div v-else class="ml-4 flex-grow">
                    <h1 class="text-2xl font-semibold leading-8 text-gray-700">{{ formData.fullName }}</h1>
                    <p class="text-md text-gray-500">
                      <a :href="`mailto: ${formData.email }`">{{ formData.email }}</a>
                    </p>
                    <p class="text-md text-gray-500">{{ formData.phone }}</p>
                  </div>
                  <div v-if="!isEdit" class="flex-shrink-0 ml-auto">
                    <PencilIcon @click="onEditClick" class="h-4 w-4 text-gray-400"/>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="mb-4">
              <div
                class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
              >
                <label for="details" class="block text-xs font-medium text-gray-700"
                  >Last Name</label
                >
                <input
                  type="text"
                  v-model="formData.lastName"
                  id="details"
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div class="mb-4">
              <div
                class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
              >
                <label for="partNumber" class="block text-xs font-medium text-gray-700"
                  >Email</label
                >
                <input
                  type="text"
                  v-model="formData.email"
                  id="partNumber"
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div class="mb-4">
              <div
                class="border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 rounded-md px-3 pb-1.5 pt-2.5"
              >
                <label for="partNumber" class="block text-xs font-medium text-gray-700"
                  >Phone</label
                >
                <input
                  type="text"
                  v-model="formData.phone"
                  id="partNumber"
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div> -->

            <!-- <div class="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                class="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <AvatarDialog
      ref="avatarDialogRef"
      :user-id="uid"
      @avatar-updated="onAvatarUpdated" />
  </div>
</template>

<script setup>
import { ref, reactive, inject, computed } from "vue";
import { $api } from "@/services/api.js";
import AvatarDialog from "@/components/AvatarDialog.vue";
import { PencilIcon} from '@heroicons/vue/20/solid'
import { useAuthStore } from '@/stores'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const emit = defineEmits(["profile-updated"]);

const isDialogOpen = ref(false);
const isEdit = ref(false);
const hovered = ref(false)

const avatarDialogRef = ref(null);

const uid = inject("uid");

const store = useAuthStore()

const initialState = {
  avatar: null,
  firstName: null,
  fullName: null,
  lastName: null,
  email: null,
  phone: false,
};

const formData = reactive({ ...initialState })

const resetForm = () => {
  Object.assign(formData, initialState)
}

const loadForm = (payload) => {
  Object.assign(formData, payload)
}

const displayProfileImage = computed(() => {
  if (formData.avatar) {
    return 'https://ik.imagekit.io/yccglvp7w/' + formData.avatar
  }
  return 'https://ui-avatars.com/api/?name=' + formData.firstName + '+' + formData.lastName
})

const onEditClick = () => {
  isEdit.value = true
}

const fetchUser = async () => {
  try {
    const data  = await $api.users.get(uid);
    loadForm(data)
  } catch (error) {
    $toast.error('Something went wrong', {
      position: 'top-right',
      duration: 3000
    }); 
  }
};

const submitForm = async () => {
  try {
    const profilePayload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
    };

    const response = await $api.users.update(uid, profilePayload);
    emit('profile-updated', response)
    isEdit.value = false;
    $toast.success('Profile updated successfully', {
      position: 'top-right',
      duration: 3000
    }); 
  } catch (error) {
    $toast.error('Something went wrong', {
      position: 'top-right',
      duration: 3000
    }); 
  }
};

const openDialog = () => {
  fetchUser();
  isDialogOpen.value = true;
};

const closeDialog = () => {
  isDialogOpen.value = false;
  resetForm();
};

const uploadAvatar = () => {
  avatarDialogRef.value.openFileDialog()
}

const onAvatarUpdated = (updatedUser) => {
  loadForm(updatedUser)
  store.updateUser({
    avatar: updatedUser.avatar
  })
}


defineExpose({
  openDialog,
});
</script>

<script>
export default {
  name: "ProfileDialog",
}
</script>