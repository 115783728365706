import axios from 'axios';
axios.defaults.withCredentials = true;

class BaseApiService {
  // baseURL = 'http://localhost:8090';
  //baseURL = 'http://localhost:3500/v1';
  baseURL = import.meta.env.VITE_APP_API_URL;
  resource 

  constructor(resource) {
    if(!resource) throw new Error('Resource is required');
    this.resource = resource;

    this.axiosInstance = axios.create({
      withCredentials: true,
    });
  }

  getUrl(id='') {
    return id ? `${this.baseURL}/${this.resource}/${id}` : `${this.baseURL}/${this.resource}`;
  }

  getUrlWithQuery(query) {
    return `${this.baseURL}/${this.resource}?${query}`;
  }

  getUrlWithPath(path) {
    return `${this.baseURL}/${this.resource}/${path}`;
  }

  getDevicesUrl(id, resource) {
    return `${this.baseURL}/devices/${id}/${resource}`;
  }  

  getBusinessUrl(id, resource) {
    return `${this.baseURL}/businesses/${id}/${resource}`;
  }

  getCourseUrl(id) {
    return `${this.baseURL}/courses/${id}/assign`;
  }

  getUserCoursesUrl(id) {
    return `${this.baseURL}/users/${id}/courses`;
  }

  handleErrors(error) {
    return error;
  }
}

class ApiService extends BaseApiService {
  constructor(resource) {
    super(resource);
  }

  async index(config={}) {
    try {
      const response = await this.axiosInstance.get(this.getUrl(), config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async indexQuery(query) {
    try {
      const response = await this.axiosInstance.get(this.getUrlWithQuery(query));
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getReadings(query) {
    try {
      const response = await this.axiosInstance.get(this.getUrlWithQuery(query));
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async create(payload, config={}) {
    try {
      const response = await this.axiosInstance.post(this.getUrl(), payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async createSubResource(subResource, payload, config={}) {
    try {
      const response = await this.axiosInstance.post(this.getUrlWithPath(subResource), payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async assignUsersToCourse(id, payload, config={}) {
    try {
      const response = await this.axiosInstance.post(this.getCourseUrl(id), payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getUserCourses(id, config={}) {
    try {
      const response = await this.axiosInstance.get(this.getUserCoursesUrl(id), config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async retrieveUserCourse(id, courseId, config={}) {
    try {
      const response = await this.axiosInstance.get(this.getUserCoursesUrl(id) + '/' + courseId, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async submitAnswers(id, payload, config={}) {
    try {
      const response = await this.axiosInstance.put(this.getUrl(id) + '/score', payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async verifyEmail(payload, config={}) {
    // try {
      const response = await this.axiosInstance.post(this.getUrl() + '/verify', payload, config);
      return response.data;
    // } catch (error) {
    //   return error;
    // }
  }

  async registerUser(payload, config={}) {
    const response = await this.axiosInstance.post(this.getUrl() + '/register', payload, config);
    return response.data;
  }

  async forgotPassword(payload, config={}) {
    const response = await this.axiosInstance.post(this.getUrl() + '/request-password-reset', payload, config);
    return response.data;
  }

  async resetPassword(payload, config={}) {
    const response = await this.axiosInstance.post(this.getUrl() + '/reset-password', payload, config);
    return response.data;
  }

  async getDevices(id, config={}) {
    const response = await this.axiosInstance.get(this.getUrl(id) + '/devices', config);
    return response.data;
  }

  async getDeviceReadings(id, config={}) {
    const response = await this.axiosInstance.get(this.getUrl(id) + '/readings', config);
    return response.data;
  }

  async bulkDelete(payload, config={}) {
    const response = await this.axiosInstance.post(this.getUrl() + '/bulkDelete', payload, config);
    return response.data;
  }

  async bulkAssign(payload, config={}) {
    const response = await this.axiosInstance.post(this.getUrl() + '/bulkAssign', payload, config);
    return response.data;
  }

  async createDeviceDemo(id, payload, config={}) {
    const response = await this.axiosInstance.post(this.getUrl(id) + '/demo', payload, config);
    return response.data;
  }

  async completeCourse(id, payload, config={}) {
    try {
      const response = await this.axiosInstance.put(this.getUrl(id) + '/complete', payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getCertificate(id, config={}) {
    const response = await this.axiosInstance.get(this.getUrl(id) + '/certificate', config);
    return response.data;
  }


  async get(id, config={}) {
    try {
      if (!id) throw new Error('Id is not provided');
      const response = await this.axiosInstance.get(this.getUrl(id), config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async update(id, payload, config={}) {
    try {
      if (!id) throw new Error('Id is not provided');
      const response = await this.axiosInstance.put(this.getUrl(id), payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async patch(id, payload) {
    try {
      const response = await this.axiosInstance.put(this.getUrl(id), payload);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async delete(id, config={}) {
    try {
      if (!id) throw new Error('Id is not provided');
      const response = await this.axiosInstance.delete(this.getUrl(id), config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }
}

class AuthApiService extends ApiService {
  constructor() {
    super('authentication');
  }

  async login(payload, config={auth: false}) {
    return await axios.post(this.getUrl('login'), payload, config);
  }

  async logout(payload, config={auth: false}) {
    return await axios.post(this.getUrl('logout'), payload, config);
  }
}

class ClientsApiService extends ApiService {
  constructor() {
    super('businesses');
  }

  async getClients(payload, config = { }) {
    try {
      return await axios.get(this.getUrl(), payload, config);
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getClient(id) {
    try {
      return await axios.get(this.getUrl(id));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getDeviceReadings(id) {
    try {
      return await axios.get(this.getDevicesUrl(id, 'readings'));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getSubscriptions(id) {
    try {
      return await axios.get(this.getBusinessUrl(id, 'subscriptions'));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getUsers(id) {
    try {
      return await axios.get(this.getBusinessUrl(id, 'users'));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async createClient(payload, config = { withCredentials: true }) {
    try {
      return await axios.post(this.getUrl(), payload, config);
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async createSubscription(id, payload) {
    try {
      return await axios.post(this.getBusinessUrl(id, 'subscriptions'), payload);
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async updateClient(id, payload, config={}) {
    try {
      if (!id) throw new Error('Id is not provided');
      const response = await this.axiosInstance.put(this.getUrl(id), payload, config);
      return response.data;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async deleteClient(id) {
    try {
      return await axios.delete(this.getUrl(id));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async bulkDeleteUsers(businessId, payload, config={}) {
    const response = await this.axiosInstance.post(this.getBusinessUrl(businessId, 'users') + '/bulkDelete', payload, config);
    return response.data;
  }
}

class ClientRolesApiService extends ApiService {
  constructor() {
    super('user-role-businesses');
  }

  async getClientRoles(payload, config = { }) {
    try {
      return await axios.get(this.getUrl(), payload, config);
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async getClientRole(id) {
    try {
      return await axios.get(this.getUrl(id));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async createClientRole(payload, config = { withCredentials: true }) {
    try {
      return await axios.post(this.getUrl(), payload, config);
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async deleteClientRole(id) {
    try {
      return await axios.delete(this.getUrl(id));
    } catch (error) {
      this.handleErrors(error);
    }
  }
}

class TenantApiService extends ApiService {
  constructor() {
    super('tenants');
  }

  async getTenant() {
    try {
      return await axios.get(this.getUrlWithPath('details'));
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async updateTenant(id, payload, config={}) {
    try {
      return await axios.put(this.getUrl(id), payload, config);
    } catch (error) {
      this.handleErrors(error);
    }
  }

}

export const $api = {
  authentication: new AuthApiService(),
  businesses: new ApiService('businesses'),
  businessRoles: new ApiService('user-role-businesses'),
  clients: new ClientsApiService(),
  clientRoles: new ClientRolesApiService(),
  contents: new ApiService('contents'),
  courses: new ApiService('courses'),
  curtsettings: new ApiService('curt-settings'),
  devices: new ApiService('devices'),
  frames: new ApiService('frames'),
  messages: new ApiService('messages'),
  plans: new ApiService('plans'),
  questions: new ApiService('questions'),
  quizzes: new ApiService('quizzes'),
  roles: new ApiService('roles'),
  sessions: new ApiService('sessions'),
  subscriptions: new ApiService('subscriptions'),
  tenantRoles: new ApiService('tenant-roles'),
  tenants: new TenantApiService(),
  userCourses: new ApiService('user-courses'),
  userSessions: new ApiService('user-sessions'),
  users: new ApiService('users'),
}