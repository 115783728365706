<template>
  <div class="flex h-screen min-h-screen flex-col">
    <!-- Top nav-->
    <header class="relative flex h-16 flex-shrink-0 items-center bg-white">
      <!-- Logo area -->
      <div class="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
        <a href="#" class="flex h-16 w-16 items-center justify-center bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:w-20">
          
        </a>
      </div>

      <!-- Mobile Picker area -->
      <div class="mx-auto md:hidden">
        <div class="relative">
          <label for="inbox-select" class="sr-only">Choose menu option</label>
          <select 
            v-model="selectedMenuItem"
            id="inbox-select" 
            class="rounded-md border-0 bg-none pl-3 pr-8 text-base font-medium text-gray-900 focus:ring-2 focus:ring-indigo-600"
          >
            <option 
              v-for="item in sidebarNavigation" 
              :key="item.name" 
              :selected="item.current"
              :value="item.href"
            >{{ item.name }}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center justify-center pr-2">
            <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </div>

      <!-- Menu button area -->
      <div class="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
        <!-- Mobile menu button -->
        <button type="button" class="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="block h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <!-- Desktop nav area -->
      <div class="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
        <h1 class="mt-2 text-3xl font-bold tracking-tight sm:text-4xl ml-6" style="color: #009444">Curteck</h1>
        <div class="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
          <div class="flex items-center space-x-8">
            <!-- <span class="inline-flex">
              <a href="#" class="-mx-1 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500">
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </a>
            </span> -->

            <Menu as="div" class="relative inline-block text-left">
              <MenuButton class="flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full mr-2" :src="displayProfileImage" alt="" /> 
                <span class="text-md">{{ user.fullName }}</span>
              </MenuButton>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <a 
                        @click="handleProfileClick"
                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">My Profile</a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a 
                        @click="signOut"
                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign Out</a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide this `div` based on menu open/closed state -->
      <TransitionRoot as="template" :show="mobileMenuOpen">
        <Dialog as="div" class="relative z-40 md:hidden" @close="mobileMenuOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75" />
          </TransitionChild>

          <div class="fixed inset-0 z-40">
            <TransitionChild as="template" enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300" enter-from="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100" enter-to="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100" leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300" leave-from="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100" leave-to="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100">
              <DialogPanel class="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg" aria-label="Global">
                <div class="flex h-16 items-center justify-between px-4 sm:px-6">
                  <a href="#">
                    <img class="block h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Curteck" />
                  </a>
                  <button 
                    type="button" 
                    class="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600" 
                    @click="mobileMenuOpen = false"
                  >
                    <span class="sr-only">Close main menu</span>
                    <XMarkIcon class="block h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="border-t border-gray-200 pt-4 pb-3">
                  <div class="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                    <div class="flex-shrink-0">
                      <img class="h-10 w-10 rounded-full" :src="displayProfileImage" alt="" />
                    </div>
                    <div class="ml-3 min-w-0 flex-1">
                      <div class="truncate text-base font-medium text-gray-800">{{ user.fullName }}</div>
                      <div class="truncate text-sm font-medium text-gray-500">{{ user.email }}</div>
                    </div>
                  </div>
                  <div class="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                    <a 
                      @click="handleProfileClick"
                      class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50">My Profile</a>
                    <a 
                      @click="signOut"
                      class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                    >Sign Out</a>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </header>

    <!-- Bottom section -->
    <div class="flex min-h-0 flex-1 overflow-hidden">
      <!-- Narrow sidebar-->
      <nav aria-label="Sidebar" 
        class="hidden md:block md:flex-shrink-0 md:overflow-y-auto md:bg-gray-800">
        <div v-if="isTenant" class="relative flex w-20 flex-col space-y-3 p-3">
          <router-link 
            v-for="item in sidebarNavigation" 
            :key="item.name" 
            :to="item.href" 
            :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700', 'inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg']"
          >
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </router-link>
        </div>
        <div v-if="isBusinessAdmin" class="relative flex w-20 flex-col space-y-3 p-3">
          <router-link 
            v-for="item in businessSidebarNavigation" 
            :key="item.name" 
            :to="item.href" 
            :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700', 'inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg']"
          >
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </router-link>
        </div>
        <div v-if="isStudent" class="relative flex w-20 flex-col space-y-3 p-3">
          <router-link 
            v-for="item in studentSidebarNavigation" 
            :key="item.name" 
            :to="item.href" 
            :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700', 'inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg']"
          >
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </router-link>
        </div>
      </nav>

      <!-- Main area -->
      <main class="min-w-0 flex-1 border-t border-gray-200 lg:flex flex-grow">
        <!-- Primary column -->
        <section aria-labelledby="primary-heading" class="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
          <h1 id="primary-heading" class="sr-only">Home</h1>
          <!-- Your content -->
          <!-- <slot /> -->
          <router-view />
        </section>
      </main>
      <Profile 
        ref="profileRef"
        @profile-updated="onProfileUpdated"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, provide, computed, watch } from 'vue'
import { useAuthStore } from '@/stores'
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { ChevronDownIcon} from '@heroicons/vue/20/solid'
import { HomeIcon, ChartBarIcon, } from '@heroicons/vue/24/solid'
import {
  AcademicCapIcon,
  Bars3Icon,
  BuildingStorefrontIcon,
  Cog6ToothIcon,
  XMarkIcon,
  UsersIcon,
} from '@heroicons/vue/24/outline'
import Profile from '@/pages/Profiles/Index.vue'
import { useRoute, useRouter } from 'vue-router'

const store = useAuthStore()
const isTenant = store.isTenant
const isStudent = store.isStudent
const isBusinessAdmin = store.isBusinessAdmin
const oid = store.user.oid
const uid= store.user.uid

const route = useRoute()
const router = useRouter()

provide('oid', oid)
provide('uid', uid)

const selectedMenuItem = ref(null)

const user = computed(() => ({
  avatar: store.user.avatar,
  fullName: store.user.fullName,
  email: store.user.email,
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}))

const tenantMenu = ref([
  // { name: 'Home', href: '/admin', icon: HomeIcon, current: true },
  { name: 'Clients', href: '/admin/clients', icon: BuildingStorefrontIcon, current: false },
  { name: 'Devices', href: '/admin/devices', icon: ChartBarIcon, current: false },
  { name: 'Curriculum', href: '/admin/curriculum', icon: AcademicCapIcon, current: false },
  { name: 'Settings', href: '/admin/settings', icon: Cog6ToothIcon, current: false },
])

const businessMenu = ref([
  // { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
  { name: 'Users', href: '/users', icon: UsersIcon, current: false },
  { name: 'CURTS', href: '/curts', icon: ChartBarIcon, current: false },
  { name: 'Curriculum', href: '/courses', icon: AcademicCapIcon, current: false },
  { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, current: false },
])

const studentMenu = ref([
  // { name: 'Home', href: '/my-dashboard', icon: HomeIcon, current: true },
  { name: 'Courses', href: '/my-courses', icon: AcademicCapIcon, current: false },
])

const userNavigation = [
  { name: 'My Profile', href: '#' },
  { name: 'Sign Out', href: '#' },
]

const mobileMenuOpen = ref(false)

const profileRef = ref(null)

const handleProfileClick = () => {
  profileRef.value.openDialog()
}

const businessSidebarNavigation = computed(() => {
  return businessMenu.value.map(item => ({
    ...item,
    current: route.path === item.href
  }))
})

const sidebarNavigation = computed(() => {
  return tenantMenu.value.map(item => ({
    ...item,
    current: route.path === item.href
  }))
})

const studentSidebarNavigation = computed(() => {
  return studentMenu.value.map(item => ({
    ...item,
    current: route.path === item.href
  }))
})

const navigateToMenuItem = () => {
  if (selectedMenuItem.value) {
    router.push(selectedMenuItem.value)
  }
}

watch(() => store.user, (newUser) => {
  localStorage.setItem('curt', JSON.stringify(newUser))
}, { deep: true })

watch(() => selectedMenuItem.value, () => {
  navigateToMenuItem()
})

const onProfileUpdated = (payload) => {
  store.updateUser({
    email: payload.email,
    fullName: payload.fullName,
    phone: payload.phone,
  })
}

const displayProfileImage = computed(() => {
  if (user.value.avatar) {
    return 'https://ik.imagekit.io/yccglvp7w/' + user.value.avatar
  }
  return 'https://ui-avatars.com/api/?name=' + store.user.fullName
})

const signOut = () => {
  store.signOut()
}
</script>