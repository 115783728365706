import { defineStore } from 'pinia'
import Cookies from 'js-cookie';
import router from '@/router/index.js'
import { $api } from '@/services/api.js'

export const useAuthStore = defineStore({
  id: 'auth',

  state: () => ({
    user: {},
    authenticated: false,
    blocked: false
  }),

  getters: {
    getUser: (state) => state.user,
    getOid: (state) => state.user.oid,
    isAuthenticated: (state) => state.authenticated,
    isBlocked: (state) => state.blocked,
    isFederation: (state) => state.user.entity === 'federation',
    isTenant: (state) => state.user.entity === 'tenant',
    isBusiness: (state) => state.user.entity === 'business',
    isBusinessAdmin: (state) => state.user.role === 'businessAdmin',
    isStudent: (state) => state.user.role === 'student',
  },

  actions: { 
    SET_USER (payload) {
      this.user = payload
      this.authenticated = true
      localStorage.setItem('curt', JSON.stringify(payload))
      localStorage.setItem('curt-auth', this.authenticated)
    },

    LOAD_USER () {
      const storedUser = localStorage.getItem('curt')
      const isAuthenticated = localStorage.getItem('curt-auth')

      if (storedUser) {
        this.user = JSON.parse(storedUser)
        this.authenticated = isAuthenticated === 'true'
        this.authenticated = true
      }
    },

    updateUser (payload) {
      this.user = {
        ...this.user,
        ...payload
      }   
    },

    async emailSignIn (payload) {
      const response = await $api.authentication.login(payload)
      this.SET_USER(response?.data)

      console.log(this.user.role)

      if (this.user.role === 'tenantAdmin') {
        router.push('/admin/clients')
      } else if (this.user.role !== 'student') {
        router.push('/users')
      } else  {
        router.push('/my-courses')
      }

    },

    async signOut () {
      try {
        await $api.authentication.logout()
        localStorage.removeItem('curt')
        localStorage.removeItem('curt-auth')
        this.user = {}
        this.authenticated = false
        router.push('/')
      } catch (error) {
        console.error(error)
      }
    },
  },
  persist: true
})
