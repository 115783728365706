import { createRouter, createWebHistory} from 'vue-router'
import routes from './routes'
import { useAuthStore } from '@/stores'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Get user authentication status from your store.
  const userStore = useAuthStore();
  const isAuthenticated = localStorage.getItem('curt-auth') || userStore.authenticated;
  // const isAuthenticated = userStore.authenticated;
  
  userStore.LOAD_USER();

  // Check if the route requires authentication.
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!isAuthenticated) {
      next({ path: '/signin' });  // Redirect to login if the user is not authenticated.
    } else {
      next();  // Allow navigation if the user is authenticated.
    }
  } else {
    next();  // Always call next() to proceed.
  }
});

export default router
