<template>
  <div
    v-if="isDialogOpen"
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <!-- <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 bg-green-500">-->
      <div class="fixed inset-0 z-10 flex items-center justify-center p-80">  
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 m-0 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm  md:max-w-xl sm:p-6  overflow-y-auto max-h-[calc(100vh - 450px)] overflow-y-auto">
          <div class="mt-0 text-center sm:mt-0">
            <div class="flex h-7 justify-end">
              <button
                @click="closeDialog"
                type="button"
                class="rounded-md text-gray-700 hover:text-gray-900"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div v-if="selectedImage.src" class="pb-4 flex items-center">
              <button @click="changeFile" class="px-4 py-2 border-2 bg-gray-200 border-gray-500 text-gray-500 font-bold rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Change File</button>
              <span class="text-gray-700 font-bold ml-10">{{  fileName }}</span>
            </div>

            <div v-if="!selectedImage.src" class="relative bg-gray-100 px-4 mt-4 w-full h-64 border-dashed border-2 border-gray-400 hover:border-gray-600 transition duration-150 cursor-pointer">
              <!-- Invisible file input positioned over the entire container -->
              <input
                ref="fileInput"
                type="file"
                class="absolute inset-0 opacity-0 w-full h-full cursor-pointer z-10"
                accept="image/*"
                @change="uploadImage($event)"
              />
              <!-- Centered file upload icon and label -->
              <div class="absolute inset-0 flex flex-col items-center justify-center space-y-4">
                <UserCircleIcon class="h-12 w-12 text-gray-400" />
                <button @click="selectFile" class="px-4 py-2 border-2 border-gray-500 text-gray-500 rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Select File</button>

              </div>
            </div>
            <cropper
              v-if="selectedImage"
              :src="selectedImage.src"
              :stencil-props="stencilProps"
              ref="cropper"
              @change="onChange"
              :canvas="{
                minHeight: 100,
                minWidth: 100,
                maxHeight: 1024,
                maxWidth: 1024,
              }"
            />
          </div>
          <div class="mt-5 sm:mt-6">
            <button 
              @click="cropImage" 
              :disabled="!selectedImage.src"
              :class="{
                'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded border-2': selectedImage,
                'bg-gray-200 text-gray-400 cursor-not-allowed font-bold py-2 px-4 rounded border-2': !selectedImage
              }">Save</button>
            <button
              @click="closeDialog"
              type="button"
              class="font-bold border-2 border-orange-300 text-orange-400 hover:bg-orange-100 hover:text-orange-600 py-2 ml-2 px-4 rounded"
            >Cancel</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { UserCircleIcon } from '@heroicons/vue/24/outline'
import { $api } from "@/services/api.js";

// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);

  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    Cropper,
    UserCircleIcon
  },
  data() {
    return {
      apiResponse: null,
      isDialogOpen: false,
      coordinates: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      selectedImage: {
        src: null,
        type: null,
      },
      fileName: '',
      fileInput: null,
      croppedImage: null,
      stencilProps: {
        aspectRatio: 1,
      },
    };
  },
  methods: {
    reset() {
      this.selectedImage = {
        src: null,
        type: null,
      };
      this.fileName = null;
    },
    openFileDialog() {
      this.isDialogOpen = true;
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    uploadImage(event) {
      const file  = event.target.files[0];
      if (file) {
        this.fileName = file.name;
        
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage.src = reader.result;
          this.selectedImage.type = getMimeType(reader.result);
        };
        reader.readAsDataURL(file);
      }
    },
    changeFile() {
      this.selectedImage.src = null;
      this.selectedImage.type = null;
      this.fileName = '';
    },
    onChange({ newCoordinates, canvas }) {
      this.coordinates = newCoordinates;
      this.croppedImage = canvas.toDataURL();
    },
    async cropImage() {
      const { canvas } = this.$refs.cropper.getResult();
      
      if (canvas) {
        const croppedImage = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, this.selectedImage.type);
        });

        const form = new FormData();
        form.append('userId', this.userId);
        form.append('file', croppedImage);

        this.apiResponse = await $api.users.createSubResource('avatar', form);

        this.$emit('avatar-updated', this.apiResponse.data);
        this.reset();
        this.closeDialog();
      }
    },
    closeDialog() {
      this.reset()
      this.isDialogOpen = false;
    },
  },
}
</script>
