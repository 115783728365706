<template>
  <component :is="currentLayout">
    <router-view />
  </component>
</template>

<script>
import AppLayoutAuthorized from './components/Layouts/AppLayoutAuthorized.vue'
import AppLayoutDefault from './components/Layouts/AppLayoutDefault.vue';
import { useAuthStore } from './stores'

export default {
  components: {
    AppLayoutAuthorized,
    AppLayoutDefault
  },
  setup() {
    const authStore = useAuthStore();
    const isAuthenticated = authStore.authenticated;
    return {
      isAuthenticated
    }
  },
  computed: {
    currentLayout() {
      // If the user is authenticated, always use layout2
      if (this.isAuthenticated) {
        return AppLayoutAuthorized;
      }
      // Otherwise, choose layout based on route metadata or default to layout1
      return this.$route.meta.layout || AppLayoutDefault;
    }
  }
}
</script>