const defaultMeta = {
  authRequired: false,
  authorize: []
}

const routes = [
  {
    path: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/Home.vue'),
        meta: {
          ...defaultMeta,
          title: 'CurTeck',
          layout: 'AppLayoutDefault',
        }
      },
      {
        path: '/about',
        component: () => import('@/pages/About.vue'),
        meta: {
          ...defaultMeta,
          title: 'About Us',
          layout: 'AppLayoutDefault',
        }
      },
      {
        path: '/services',
        component: () => import('@/pages/Services.vue'),
        meta: {
          ...defaultMeta,
          title: 'Our Services',
          layout: 'AppLayoutDefault',
        }
      },
      {
        path: '/videos',
        component: () => import('@/pages/Videos.vue'),
        meta: {
          ...defaultMeta,
          title: 'Videos',
          layout: 'AppLayoutDefault',
        }
      },
      {
        path: '/bio',
        component: () => import('@/pages/Bio.vue'),
        meta: {
          ...defaultMeta,
          title: 'Bio',
          layout: 'AppLayoutDefault',
        }
      },
      {
        path: '/contact',
        component: () => import('@/pages/Contact.vue'),
        meta: {
          ...defaultMeta,
          title: 'Contact Us',
          layout: 'AppLayoutDefault',
        }
      },
      {
        name: 'SignIn',
        path: '/signin',
        component: () => import('@/pages/Authentication/SignIn.vue'),
        meta: {
          ...defaultMeta,
          title: 'Sign In',
          layout: 'AppLayoutDefault',
        }
      },
      {
        name: 'VerifyEmail',
        path: '/verify',
        component: () => import('@/pages/Authentication/VerifyEmail.vue'),
        meta: {
          ...defaultMeta,
          title: 'Verify Email',
          layout: 'AppLayoutNoHeader',
        }
      },
      {
        name: 'Register',
        path: '/register',
        component: () => import('@/pages/Authentication/RegisterUser.vue'),
        meta: {
          ...defaultMeta,
          title: 'Register',
          layout: 'AppLayoutNoHeader',
        }
      },
      {
        name: 'ForgotPassword',
        path: '/forgot-password',
        component: () => import('@/pages/Authentication/ForgotPassword.vue'),
        meta: {
          ...defaultMeta,
          title: 'Forgot Password',
          layout: 'AppLayoutNoHeader',
        }
      },
      {
        name: 'ResetPassword',
        path: '/reset-password',
        component: () => import('@/pages/Authentication/ResetPassword.vue'),
        meta: {
          ...defaultMeta,
          title: 'Reset Password',
          layout: 'AppLayoutNoHeader',
        }
      },
      {
        path: '/dashboard',
        component: () => import('@/pages/Businesses/Dashboard.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/courses',
        component: () => import('@/pages/Businesses/BusinessCourses.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/my-dashboard',
        component: () => import('@/pages/Students/Dashboard.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/my-courses',
        name: 'UserCourses',
        component: () => import('@/pages/Students/Courses.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/my-courses/:courseId',
        component: () => import('@/pages/Students/CourseDetails.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/admin',
        component: () => import('@/pages/Tenants/Dashboard.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/admin/clients',
        name: 'Clients',
        component: () => import('@/pages/Clients/Index.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/admin/clients/:clientId',
        name: 'ClientDetails',
        component: () => import('@/pages/Clients/ClientDetails.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/admin/curriculum',
        component: () => import('@/pages/Curriculum/Index.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/admin/devices',
        component: () => import('@/pages/Devices/Index.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/curts',
        component: () => import('@/pages/Devices/BusinessDevices.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/settings',
        component: () => import('@/pages/Businesses/Settings.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/admin/settings',
        component: () => import('@/pages/Settings/Index.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/users',
        name: 'BusinessUsers',
        component: () => import('@/pages/Businesses/Users.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
      {
        path: '/users/:userId',
        name: 'UserDetails',
        component: () => import('@/pages/Businesses/UserDetails.vue'),
        meta: {
          layout: 'AppLayoutAuthorized',
          authRequired: true,
          authorize: []
        }
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/ErrorNotFound.vue')
  }
]

export default routes
